import { css } from 'styled-components';

import { colors } from '@/constants/theme';

export const ScrollStyles = css`
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  /* Track */

  &::-webkit-scrollbar-track {
    background: transparent;
    border: 1px solid #919caf;
    border-radius: 29px;
  }

  /* Handle */

  &::-webkit-scrollbar-thumb {
    background: ${colors.red};
    border-radius: 29px;
  }

  /* firefox */

  scrollbar-width: thin;
`;
