export const coordinateTeamItems = [
  { leftX: '300', topY: '150', width: '24' },
  { leftX: '99', topY: '713', width: '260' },
  { leftX: '489', topY: '758', width: '118' },
  { leftX: '819', topY: '451', width: '96' },
  { leftX: '518', topY: '476', width: '118' },
  { leftX: '270', topY: '328', width: '33' },
  { leftX: '383', topY: '357', width: '118' },
  { leftX: '853', topY: '908', width: '271' },
  { leftX: '368', topY: '951', width: '137' },
  { leftX: '623', topY: '216', width: '24' },
  { leftX: '819', topY: '85', width: '75' },
  { leftX: '619', topY: '246', width: '72' },
  { leftX: '1167', topY: '241', width: '196' },
  { leftX: '915', topY: '199', width: '59' },
  { leftX: '1095', topY: '444', width: '72' },
  { leftX: '320', topY: '253', width: '55' },
  { leftX: '1001', topY: '333', width: '63' },
  { leftX: '663', topY: '1120', width: '36' },
  { leftX: '1000', topY: '611', width: '196' },
  { leftX: '1205', topY: '489', width: '115' },
  { leftX: '470', topY: '317', width: '32' },
  { leftX: '390', topY: '325', width: '24' },
  { leftX: '809', topY: '572', width: '118' },
  { leftX: '290', topY: '530', width: '42' },
  { leftX: '791', topY: '884', width: '72' },
  { leftX: '695', topY: '949', width: '88' },
  { leftX: '589', topY: '978', width: '40' },
  { leftX: '524', topY: '906', width: '48' },
  { leftX: '699', topY: '885', width: '64' },
  { leftX: '600', topY: '835', width: '96' },
  { leftX: '722', topY: '776', width: '118' },
  { leftX: '675', topY: '544', width: '118' },
  { leftX: '613', topY: '592', width: '48' },
  { leftX: '594', topY: '656', width: '72' },
  { leftX: '629', topY: '721', width: '96' },
  { leftX: '678', topY: '670', width: '32' },
  { leftX: '722', topY: '674', width: '72' },
  { leftX: '810', topY: '694', width: '24' },
  { leftX: '534', topY: '605', width: '64' },
  { leftX: '395', topY: '691', width: '24' },
  { leftX: '367', topY: '630', width: '48' },
  { leftX: '379', topY: '590', width: '32' },
  { leftX: '352', topY: '498', width: '72' },
  { leftX: '367', topY: '462', width: '24' },
  { leftX: '319', topY: '413', width: '48' },
  { leftX: '281', topY: '379', width: '29' },
  { leftX: '656', topY: '441', width: '96' },
  { leftX: '746', topY: '391', width: '72' },
  { leftX: '814', topY: '343', width: '64' },
  { leftX: '900', topY: '383', width: '48' },
  { leftX: '951', topY: '476', width: '71' },
  { leftX: '1025', topY: '514', width: '31' },
  { leftX: '1056', topY: '506', width: '24' },
  { leftX: '1005', topY: '558', width: '54' },
  { leftX: '434', topY: '480', width: '72' },
  { leftX: '513', topY: '344', width: '64' },
  { leftX: '576', topY: '388', width: '72' },
  { leftX: '867', topY: '742', width: '118' },
  { leftX: '647', topY: '324', width: '96' },
  { leftX: '748', topY: '321', width: '48' },
  { leftX: '552', topY: '696', width: '24' },
  { leftX: '427', topY: '707', width: '69' },
  { leftX: '494', topY: '665', width: '48' },
  { leftX: '418', topY: '632', width: '64' },
  { leftX: '420', topY: '572', width: '48' },
  { leftX: '480', topY: '587', width: '48' },
  { leftX: '515', topY: '424', width: '48' },
  { leftX: '303', topY: '324', width: '72' },
  { leftX: '589', topY: '324', width: '48' },
  { leftX: '721', topY: '293', width: '32' },
  { leftX: '933', topY: '573', width: '48' },
  { leftX: '802', topY: '728', width: '49' },
  { leftX: '623', topY: '465', width: '24' },
  { leftX: '645', topY: '541', width: '32' },
  { leftX: '-54', topY: '329', width: '331' },
  { leftX: '760', topY: '481', width: '48' },
  { leftX: '260', topY: '581', width: '72' },
  { leftX: '920', topY: '661', width: '48' },
  { leftX: '960', topY: '391', width: '72' },
  { leftX: '850', topY: '420', width: '24' },
  { leftX: '1070', topY: '370', width: '48' },
  { leftX: '950', topY: '370', width: '24' },
  { leftX: '930', topY: '350', width: '24' },
  { leftX: '886', topY: '313', width: '32' },
  { leftX: '200', topY: '640', width: '48' },
  { leftX: '280', topY: '680', width: '32' },
  { leftX: '290', topY: '480', width: '32' },
  { leftX: '390', topY: '820', width: '72' },
  { leftX: '360', topY: '780', width: '48' },
  { leftX: '320', topY: '700', width: '24' },
  { leftX: '340', topY: '740', width: '32' },
  { leftX: '490', topY: '1040', width: '118' },
  { leftX: '630', topY: '940', width: '48' },
  { leftX: '645', topY: '1000', width: '32' },
  { leftX: '650', topY: '1050', width: '24' },
  { leftX: '520', topY: '730', width: '24' },
  { leftX: '570', topY: '725', width: '32' },
  { leftX: '350', topY: '890', width: '48' },
  { leftX: '430', topY: '900', width: '32' },
  { leftX: '480', topY: '900', width: '24' },
  { leftX: '480', topY: '930', width: '24' },
  { leftX: '507', topY: '965', width: '66' },
  { leftX: '720', topY: '1050', width: '118' },
  { leftX: '1000', topY: '810', width: '92' },
  { leftX: '1100', topY: '530', width: '72' },
  { leftX: '1040', topY: '430', width: '48' },
  { leftX: '912', topY: '440', width: '48' },
  { leftX: '906', topY: '520', width: '32' },
  { leftX: '790', topY: '530', width: '32' },
  { leftX: '790', topY: '530', width: '32' },
  { leftX: '860', topY: '700', width: '48' },
  { leftX: '865', topY: '860', width: '48' },
  { leftX: '800', topY: '990', width: '48' },
  { leftX: '580', topY: '1140', width: '72' },
  { leftX: '670', topY: '1170', width: '48' },
  { leftX: '670', topY: '1170', width: '48' },
  { leftX: '670', topY: '1075', width: '32' },
  { leftX: '620', topY: '1075', width: '24' },
  { leftX: '600', topY: '1020', width: '48' },
  { leftX: '550', topY: '920', width: '32' },
  { leftX: '1200', topY: '620', width: '92' },
  { leftX: '1168', topY: '430', width: '72' },
  { leftX: '1160', topY: '500', width: '42' },
  { leftX: '1174', topY: '550', width: '32' },
  { leftX: '1200', topY: '600', width: '24' },
  { leftX: '1150', topY: '600', width: '24' },
  { leftX: '960', topY: '720', width: '32' },
  { leftX: '960', topY: '620', width: '48' },
  { leftX: '1100', topY: '850', width: '118' },
  { leftX: '1200', topY: '750', width: '92' },
  { leftX: '1100', topY: '807', width: '48' },
  { leftX: '200', topY: '500', width: '92' },
  { leftX: '305', topY: '535', width: '48' },
  { leftX: '330', topY: '635', width: '32' },
  { leftX: '70', topY: '610', width: '118' },
  { leftX: '1050', topY: '220', width: '118' },
  { leftX: '920', topY: '260', width: '92' },
  { leftX: '810', topY: '260', width: '72' },
  { leftX: '710', topY: '210', width: '72' },
  { leftX: '450', topY: '260', width: '92' },
  { leftX: '560', topY: '270', width: '48' },
  { leftX: '400', topY: '270', width: '32' },
  { leftX: '100', topY: '200', width: '118' },
  { leftX: '190', topY: '310', width: '72' },
  { leftX: '220', topY: '400', width: '72' },
  { leftX: '130', topY: '110', width: '72' },
  { leftX: '100', topY: '160', width: '32' },
  { leftX: '20', topY: '250', width: '72' },
  { leftX: '60', topY: '190', width: '48' },
  { leftX: '320', topY: '150', width: '92' },
  { leftX: '240', topY: '250', width: '72' },
  { leftX: '210', topY: '150', width: '92' },
  { leftX: '560', topY: '150', width: '72' },
  { leftX: '560', topY: '120', width: '24' },
  { leftX: '460', topY: '220', width: '32' },
];
