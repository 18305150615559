import { PrepareVmValues } from '@/components/forms/DynamicContactsForm/types';
import { sendSingleCourseForm } from '@/services/requests';

export const handleSendCourseForm = async (
  courseId: number,
  values: PrepareVmValues
): Promise<void> => {
  try {
    await sendSingleCourseForm({
      id: courseId,
      fields: values,
    });
  } catch (error: any) {
    return Promise.reject(error);
  }
};
