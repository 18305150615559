import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { DynamicContactsForm } from '@/components/forms/DynamicContactsForm';
import { ScrollStyles } from '@/components/styles';

import CloseButton from '../shared/CloseButton';

import { DynamicContactsFormModalProps } from './types';

function DynamicContactsFormModal({
  innerProps,
  closeModal,
}: DynamicContactsFormModalProps) {
  const { title, formText, formFields, submitAsync } = innerProps;

  return (
    <Wrapper>
      <ButtonWrapper>
        <CloseButton onClick={closeModal} />
      </ButtonWrapper>
      <Title>{title ? title : 'Заполните форму'}</Title>
      <FormWrapper>
        <DynamicContactsForm fields={formFields} submitAsync={submitAsync} />
      </FormWrapper>

      {formText && (
        <FormText dangerouslySetInnerHTML={{ __html: formText ?? '' }} />
      )}
    </Wrapper>
  );
}

export default DynamicContactsFormModal;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 60px;
  width: 70vw;
  height: 80vh;
  background: ${colors.dark};
  overflow-y: auto;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;

  ${ScrollStyles}

  ${media.tablet(css`
    min-width: 100vw;
    min-height: 100vh;
  `)}

  ${media.mobile(css`
    padding: 60px 20px;
  `)}
`;

const ButtonWrapper = styled.div`
  position: fixed;
  top: 35px;
  right: 60px;
  display: inline-flex;

  ${media.mobile(css`
    right: 20px;
  `)}
`;

const Title = styled.span`
  font-weight: 900;
  font-size: 58px;
  line-height: 130%;
  text-transform: uppercase;
  text-align: center;
  color: ${colors.white};

  ${media.tablet(css`
    font-size: 56px;
  `)}

  ${media.mobile(css`
    font-size: 40px;
  `)}
`;

const FormWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const FormText = styled.div`
  margin-top: 35px;
  font-size: 24px;
  line-height: 150%;
  color: ${colors.white};

  ${media.tablet(css`
    font-size: 16px;
  `)}

  b,
  strong {
    color: ${colors.red};
  }

  p {
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }
  }

  ul {
    margin-top: 10px;
  }

  li {
    padding-left: 25px;
    position: relative;

    &:before {
      content: '';
      left: 2px;
      top: 13px;
      width: 8px;
      height: 8px;
      background: ${colors.red};
      border-radius: 100%;
      position: absolute;

      ${media.mobile(css`
        width: 6px;
        height: 6px;
        top: 9px;
        left: 5px;
      `)}
    }
  }
`;
